<script lang="ts">
    import WorkerCard from "../components/WorkerCard.svelte";
	import type { EspoResponseList, Market } from '../types/types';
    import { Jumbotron, Row, Col, Image} from "@sveltestrap/sveltestrap";
    import Button from "../components/Button.svelte";
	import { onMount } from 'svelte';
	import type { AjaxPromise } from 'espocrm/src/ajax';

    let workers = [];
    let columnsCount = 2;

    let selectedMarket: Market = "CZ";
    onMount(async () => {
        steelworkers(selectedMarket);
    });

    function steelworkers(market: Market):void {
        selectedMarket = market;
		(Espo.Ajax.getRequest("WarehouseWorker",{select: 'id,name,market,photoId'}) as AjaxPromise<EspoResponseList<any>>)
			.then((response) => {
				workers = response.list;
				console.log(workers);
        });
        return;
    }
   $: selectedMarket, steelworkers(selectedMarket);
   function exitKiosk():void {
        console.log("exit");
        document.title="#EXITKIOSK";
        return;
    }
</script>

<Row>
    <Col>
        <Jumbotron>
            <Row>
                <Col>
                    <a color="danger" class="m-2 btn  btn-outline-danger" href="/"
                        >Zpět do CRM</a
                    >
                </Col>
                <Col >
                    <Button color="danger" class="m-2"
                        on:click={() => exitKiosk()}
                        style="float: right;"
                    >X
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col class="d-flex align-items-center mr-5">
                <h1 class="display-4"
                    style="white-space: nowrap; margin-right: 6rem;">
                    Portál expedice

                </h1>
                </Col>
                <Col>
                <Image
                        fluid
                        alt="AutoERP"
                        src="client/modules/expedition-portal/lib/expedition-portal/autoerp-brand-logo-dark.png"
                    />
                </Col>
            </Row>
            <hr />
            <Row class="mb-3">
                <Col class="d-flex"  style="align-items: center;">
                    <h3 >Prosím přihlaste se:</h3>
                </Col>

            </Row>

            {#each Array(Math.ceil(workers.length / columnsCount)) as _, i}
                <Row >
                    {#each workers.slice(i * columnsCount, i*columnsCount+columnsCount) as worker, j}
                        <WorkerCard
                            name={worker.name}
                            id={worker.id}
                            workerMarket={worker.market}
                            columnsCount={columnsCount}
                            imageUrl={worker.photoId ? `/?entryPoint=image&id=${worker.photoId}` : "https://placehold.co/400"}
                        />
                    {/each}
                </Row>
            {/each}

        </Jumbotron>
    </Col>
</Row>
