<script lang="ts">
    import Queue from "../components/Queue.svelte";
    import { market, worker, order, queue } from "../stores";
    import { push, replace } from "svelte-spa-router";
    import Button from "../components/Button.svelte";
    import { Jumbotron, Row, Col, Image, Modal, ModalHeader, ModalBody,  DropdownItem,DropdownMenu,DropdownToggle,Dropdown } from "@sveltestrap/sveltestrap";
    import { onMount } from "svelte";
	import type { ApiResponse, CustomerOrderData, EspoResponseList, ExpeditionQueStatus, Market } from '../types/types';
	import type { AjaxPromise } from 'espocrm/src/ajax';

    if ($worker == null) {
        replace("/");
    }
    let queues: ExpeditionQueStatus = [];

    function selectExpedition(marketSelected: Market):void {
        market.set(marketSelected);
		(Espo.Ajax.getRequest("SalesOrder/statusCheck",{market: marketSelected}) as AjaxPromise<ApiResponse<ExpeditionQueStatus>>)
			.then((response: ApiResponse<ExpeditionQueStatus>) => {
				if (response.success) {
					queues = response.data;
					console.log(response);
				}else if (response.success === false){
					console.log(response.message)
				}
        });
    }

    let selectedMarket: Market = $market as Market??"CZ";
    onMount(async () => {
        selectExpedition(selectedMarket);
    });
    $: selectedMarket, selectExpedition(selectedMarket);


    function logoutUser() {
        worker.set(null);
        push("/");
    }
    // restore order when user refreshes page
    let open = false;
    const toggle = () => (open = !open);

    let existingOrder = {};
	(Espo.Ajax.getRequest("SalesOrder",{
        where: [
            {
                type: 'and',
                value: [
                    {
                        type: 'equals',
                        attribute: 'warehouseWorkerId',
                        value: $worker,
                    },
                    {
                        type: 'equals',
                        attribute: 'status',
                        value: 'in-progress',
                    }
                ]
            }
        ],
        select: 'id,total,name',
    }) as AjaxPromise<EspoResponseList<any>>).then((response: EspoResponseList<any>) => {
        existingOrder = response.list;

        if (response.total > 0) {
            toggle();
        }
        console.log("objednavka");
        console.log(existingOrder);
    });



    let restore = true;
    function continueOrder() {
        restore = false;
		(Espo.Ajax.postRequest("SalesOrder/continueOrder", {orderId: existingOrder[0].id}) as AjaxPromise<ApiResponse<CustomerOrderData>>)
			.then((response: ApiResponse<CustomerOrderData>) => {
				if (response.success) {
					console.log(response.data);
					market.set(response.data.market);
					order.set(response.data);
					queue.set(response.data.carrier);
					push("/expedition");
					console.log(response);
				}else if (response.success === false){
					toggleAlertModal(response.message);
					console.log(response);
				}
        });
    }

    function unRestoreOrder() {
        if (restore){
            console.log('zavre')
            if (existingOrder[0].id != null) {
                Espo.Ajax.putRequest("SalesOrder/" + existingOrder[0].id , {
                    'warehouseWorkerId': null,
                }).then(() => {
					(Espo.Ajax.getRequest("SalesOrder/statusCheck",{market: $market})as AjaxPromise<ApiResponse<ExpeditionQueStatus>>)
						.then((response:ApiResponse<ExpeditionQueStatus>) => {
							if (response.success) {
								queues = response.data;
								console.log(queues);
							}else if (response.success === false){
								console.log(response.message)
							}
                    });
                });

            } else {
                console.log("No existing order found");
            }
        }
    }
    let message = "";
    let alertModal = false;
    const toggleAlertModal = (alertMessage="") => {
        message = alertMessage;
        return alertModal = !alertModal;
    };
</script>

<Modal id="modal" isOpen={alertModal} toggle={toggleAlertModal}  size="xl" centered>
    <ModalBody>
        <h1>{message}</h1>
        <div class="w-100 d-inline-flex pt-4">
            <Button color="secondary" class="btn-block w-100 error-item" on:click={() => toggleAlertModal()}>Ok</Button>
        </div>
    </ModalBody>
</Modal>
<Modal isOpen={open} {toggle} on:close={unRestoreOrder} size="xl" class="modal-dialog modal-dialog-centered">
    <ModalHeader {toggle}>Vráti se k Objednávce</ModalHeader>
    <ModalBody style="font-size: x-large;">
      Našli jsme neukončenou objednávku. Chcete se k ní vrátit?
      <div class="w-100 d-inline-flex pt-4" style="height: 130px;">
        <Button color="primary"  class="btn-block w-100 me-3" on:click={continueOrder}>Ano</Button>
        <Button color="secondary" class="btn-block w-100 ms-3" on:click={toggle}>Ne</Button>
      </div>
    </ModalBody>

</Modal>

<Row>
    <Col>
        <Jumbotron>

            <Button color="outline-danger" class="m-2"   on:click={logoutUser}
                >Odhlásit se</Button
            >
            <Row>
                <Col class="d-flex align-items-center mr-5">
                <h1 class="display-4"
                    style="white-space: nowrap; margin-right: 6rem;">
                    Portál expedice

                </h1>
                </Col>
                <Col>
                <Image
                        fluid
                        alt="AutoERP"
                        src="client/modules/expedition-portal/lib/expedition-portal/autoerp-brand-logo-dark.png"
                    />
                </Col>
            </Row>
            <hr />
            <Row class="mb-3">
                <Col class="d-flex"  style="align-items: center;">
                    <h3 >Prosím vyberte frontu:</h3>
                </Col>
                <Col class="d-flex justify-content-end">
                    <Col class="d-flex justify-content-end">
                        <Dropdown autoClose={true} size="lg">
                            <DropdownToggle caret style="width: 150px; height: 80px;" >
                                {selectedMarket}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    style="width: 150px; height: 80px;"
                                    active={selectedMarket==="CZ"}
                                    on:click={() => selectedMarket="CZ"}>
                                    CZ</DropdownItem>

                                <DropdownItem
                                    style="width: 150px; height: 80px;"
                                    active={selectedMarket==="SK"}
                                    on:click={() => selectedMarket="SK"}>
                                    SK
                                </DropdownItem>

                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Col>
            </Row>
            <div class="mb-5">
                {#each queues as queue}
                    <Col>
                        <Queue
                            queueData={queue}
                        />
                    </Col>
                {/each}
            </div>
        </Jumbotron>
    </Col>
</Row>
