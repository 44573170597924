<script lang="ts">
    import { Modal, ModalBody, Icon } from "@sveltestrap/sveltestrap";
    import Button from "./Button.svelte";
    import type { Item, CustomerOrderData } from "../types/types";
	import { QRCode } from '@castlenine/svelte-qrcode';
	import { boxRowsOpen, order } from '../stores';

    export let item: Item;

    function updateItemCount(itemID: string, count: number, confirm: boolean = false) {
        if (item.productId === itemID && item.scannedQuantity + count >= 0) {
            if ((item.scannedQuantity + count > 1 || $order.boxCount + count > 1)
                 && confirm === false && $order.boxCount <= 1 && count > 0) {
                toggle();
                return;
            }
            item.scannedQuantity += count;
            if (item.type === "KRABICE") {

                $order.boxCount += count;
                if ($order.boxCount >= 1) {
                    $order.boxStatus = "completed";
                    if ($order.boxCount == 1 && count == 1) {
                         $boxRowsOpen = false;
                    }
                } else {
                    $order.boxStatus = "not-scanned";
                }
            }
        }
    }
    let open = false;
    const toggle = () => open = !open;
    enum BoxName {
        "210 x 155 x 100 3 VVL" = "Nejmenší",
        "310 x 210 x 100 3 VVL" = "Menší",
        "310 x 210 x 150 3 VVL" = "Střední",
        "310 x 210 x 200 3 VVL" = "Velká",
    }
	$: qrData = item.ean;
</script>

<Modal id="modal" isOpen={open} {toggle}  size="xl" centered>
    <ModalBody>
      <h1>Chcete přidat více krabic(1) pokud ano objednávka bude rozdělena na více kusů? </h1>
      <div class="w-100 d-inline-flex pt-4">
        <Button color="primary" class="btn-block w-100 me-3 error-item" on:click={() => {updateItemCount(item.productId, 1, true);toggle()}}>Ano</Button>
        <Button color="secondary" class="btn-block w-100 ms-3 error-item" on:click={toggle}>Ne</Button>
      </div>
    </ModalBody>
</Modal>
<div class="item" style="--row-style: 2fr auto auto;">


    <div class="item-name">{BoxName[item.name] || item.name}</div>
    <div class="item-quantity">
        {item.scannedQuantity ?? 0}
    </div>
    <div class="item-price">
        <Button on:click={() => {updateItemCount(item.productId, 1)}}><Icon name="plus-lg" /></Button>
        <Button on:click={() => updateItemCount(item.productId, -1)}><Icon name="dash-lg" /></Button>
    </div>

    <div>

		{#key item.ean}
			<div>
				<QRCode data={item.ean} padding={5} size={100} />
			</div>
		{/key}
<!--
        <QRCode data={item.ean} size={100} padding={5}/>
-->
    </div>

</div>

<style>

    .item {
        display: grid;
        grid-template-columns: var(--row-style);
        grid-template-rows: 1fr 1fr;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
        padding: 0.5rem;
        margin: 1rem;
    }

    .item-name {
        font-size: 2rem;
        font-weight: 700;
    }

    .item-quantity {
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        grid-row: span 2;
        margin-right: 1rem;
    }

    .item-price {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        grid-row: span 2;
    }

</style>
