<script lang="ts">
    import Button from "./Button.svelte";
    import { Col } from "@sveltestrap/sveltestrap";

    export let quantity: string;
</script>
<Col style="display: flex; flex-direction: column; align-items: center;">
	<Col class="d-flex">
		Kolik položek chcete naskenovat: {quantity}
	</Col>
	<Col class="d-flex mt-2 mb-2">
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "1" : quantity +"1"}>1</Button>
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "2" : quantity +"2"}>2</Button>
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "3" : quantity +"3"}>3</Button>
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "4" : quantity +"4"}>4</Button>
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "5" : quantity +"5"}>5</Button>
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "6" : quantity +"6"}>6</Button>
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "7" : quantity +"7"}>7</Button>
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "8" : quantity +"8"}>8</Button>
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "9" : quantity +"9"}>9</Button>
		<Button class="me-2" on:click={() => quantity= (quantity === "_") ? "0" : quantity +"0"}>0</Button>

		<Button class="me-2" on:click={() => quantity= quantity = (quantity.length === 1) ? "_" : quantity.slice(0, -1)}><i class="bi bi-backspace"></i></Button>
	</Col>

</Col>
