export const addAlpha = (color: string, opacity: number = 0.5) => {
	if (color == null) {
		return '#000000';
	}

	if (color.length === 4) {
		// Expand shorthand notation like "#000" to "#000000"
		color = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
	}

	// Convert the hex color to its RGB components
	const red = parseInt(color.slice(1, 3), 16);
	const green = parseInt(color.slice(3, 5), 16);
	const blue = parseInt(color.slice(5, 7), 16);

	// Use the rgba() function with the RGB components and opacity
	return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
};
