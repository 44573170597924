<script lang="ts">
    import { Card, CardBody, CardTitle, Modal,ModalBody } from "@sveltestrap/sveltestrap";
    import Button from "./Button.svelte";
    import { queue, order, market, worker } from "../stores";
    import { push } from "svelte-spa-router";
	import type { ApiResponse, CustomerOrderData, ExpeditionQueueData } from '../types/types.js';
	import type { AjaxPromise } from 'espocrm/src/ajax';


    export let queueData:ExpeditionQueueData;

    function selectQueue() {
        queue.set(queueData.internalName);

        let data = {
            'warehouseWorkerId': $worker,
            'queue': $queue,
            'market': $market,
        }
        console.log(data);
		(Espo.Ajax.postRequest("SalesOrder/reserveOrder", data) as AjaxPromise<ApiResponse<CustomerOrderData>>)
			.then((response:ApiResponse<CustomerOrderData>) => {
				if (response.success) {
					order.set(null)
					order.set(response.data);
					console.log($order);
					push("/expedition");
				}else if (response.success === false){
					toggleAlertModal(response.message);
				}
        });

    }
    let message = "";
    let alertModal = false;
    const toggleAlertModal = (alertMessage="") => {
        message = alertMessage;
        return alertModal = !alertModal;
    };
</script>
    <Modal id="modal" isOpen={alertModal} toggle={toggleAlertModal}  size="xl" centered>
        <ModalBody>
            <h1>{message}</h1>
            <div class="w-100 d-inline-flex pt-4">
                <Button color="secondary" class="btn-block w-100 error-item" on:click={() => toggleAlertModal()}>Ok</Button>
            </div>
        </ModalBody>
    </Modal>
    <Card body
        style="background-color: {queueData.color}; height:fit-content; cursor: pointer; text-align: center; justify-content: center; "

        class="text-center mb-4 font-weight-bold text-white display-4"
        on:click={selectQueue}
        >
           <CardTitle style="font-size: 1.5rem;" class="mt-2"> {queueData.name}</CardTitle>
        <CardBody>
            {queueData.count}

        </CardBody>
    </Card>
