<script lang="ts">
    import { Button } from "@sveltestrap/sveltestrap";
    import type { Item, CustomerOrderData, BundleItem } from "../types/types";
	import { QRCode } from '@castlenine/svelte-qrcode';
	import { order } from "../stores";
    export let item: Item;
    export let disableImage = false;

    function removeItem(itemID: string) {

        if (item.productId === itemID && item.scannedQuantity - 1 >= 0) {
            item.scannedQuantity -= 1;
            if (item.type === "KRABICE") {
                const boxCount = $order.items.reduce((acc, item) => {
                    if (item.type === "KRABICE") {
                        acc += item.scannedQuantity;
                    }
                    return acc;
                }, 0);
                $order.boxCount = boxCount;
                if (boxCount >= 1) {
                    $order.boxStatus = "completed";
                } else {
                    $order.boxStatus = "not-scanned";
                }
            } else {
                if (item.scannedQuantity >= item.orderQuantity) {
                    item.status = "completed";
                } else {
                    item.status = "not-scanned";
                }
            }
            if (item.scannedQuantity === 0 && item.isGift === true) {
                $order.items = $order.items.filter(
                    (i) => i.isGift !== true || i.productId !== itemID
                );
            }
        }
    }
    let rowStyle = item.isGift ? "auto 2fr" : "auto 2fr";

	$: qrData = item.ean;
</script>

{#if item.isGift && item.status != "completed"}
<div class="item {item.status}" style="--row-style:{rowStyle};background: #ffc5ff;">
    {#if !disableImage}
        <div class="item-image">
            <img
                src={item.image}
                alt={item.name}
                width="160"
            />
        </div>
    {/if}

    <div class="item-name">
        <div class="item-name">Dárek 🎁</div>
        {item.name}</div>
    <div class="item-ean">

        <div class="item-quantity {item.orderQuantity > 1 ? item.status : ''}">
            {item.scannedQuantity ?? 0} / {item.orderQuantity ?? 0}

        </div>

		{#key item.ean}
			<div>
				<QRCode data={item.ean} padding={5} size={100} />
			</div>
		{/key}




        <!--{#if item.isGift}
            <div class="item-price">
                <Button on:click={() => removeItem(item.productId)}>X</Button>
            </div>
        {/if}-->

    </div>

    <!-- <div class="item-price">{item.price ?? 0} {order.totalPriceCurrency ?? "CZK"} <br />cena za 1 ks</div> -->



</div>
{/if}
{#if item.isGift && item.status == "completed"}
<div class="item {item.status}" style="--row-style:{rowStyle};background: #9acd32;">
    {#if !disableImage}
        <div class="item-image">
            <img
                src={item.image}
                alt={item.name}
                width="160"
            />
        </div>
    {/if}

    <div class="item-name">
        <div class="item-name">Dárek 🎁</div>
        {item.name}</div>
    <div class="item-ean">

        <div class="item-quantity {item.orderQuantity > 1 ? item.status : ''}">
            {item.scannedQuantity ?? 0} / {item.orderQuantity ?? 0}

        </div>

		{#key item.ean}
			<div>
				<QRCode data={item.ean} padding={5} size={100} />
			</div>
		{/key}




        <!--{#if item.isGift}
            <div class="item-price">
                <Button on:click={() => removeItem(item.productId)}>X</Button>
            </div>
        {/if}-->

    </div>

    <!-- <div class="item-price">{item.price ?? 0} {order.totalPriceCurrency ?? "CZK"} <br />cena za 1 ks</div> -->



</div>
{/if}
{#if !item.isGift}
<div class="item {item.status}" style="--row-style:{rowStyle}">
    {#if !disableImage}
        <div class="item-image">
            <img
                src={item.image}
                alt={item.name}
                width="160"
            />
        </div>
    {/if}

    <div class="item-name">
        {item.name}</div>
    <div class="item-ean">

        <div class="item-quantity {item.orderQuantity > 1 ? item.status : ''}">
            {item.scannedQuantity ?? 0} / {item.orderQuantity ?? 0}

        </div>

		{#key item.ean}
			<div>
				<QRCode data={item.ean} padding={5} size={100} />
			</div>
		{/key}




        <!--{#if item.isGift}
            <div class="item-price">
                <Button on:click={() => removeItem(item.productId)}>X</Button>
            </div>
        {/if}-->

    </div>

    <!-- <div class="item-price">{item.price ?? 0} {order.totalPriceCurrency ?? "CZK"} <br />cena za 1 ks</div> -->



</div>
{/if}

<style>

    img {
        margin-right: 20px;
    }
    .item-ean {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
        margin-top: -20px;
    }
    .quantity-buttons {
        display: flex;
        justify-content: center;
    }

    .item-quantity.not-scanned {
        color: red;
    }
    .item-quantity.completed {
        color: black;
    }
    .item.not-scanned {
        background-color: #f4f5d4;
    }
    .item.completed {
        background-color: yellowgreen;
    }
    .item {
        display: grid;
        grid-template-columns: var(--row-style);
        grid-template-rows: 1fr 1fr;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
        padding: 0.5rem;
        margin: 1rem;
    }

    .item-image {
        grid-row: span 2;
        text-align: center;
        word-break: break-word;
    }

    .item-name {
        font-size: 2rem;
        font-weight: 700;
    }

    .item-edition {
        font-size: 1.5rem;
        color: darkgray;
        padding-bottom: 1rem;
    }

    .item-quantity {
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        grid-row: span 2;
        width: fit-content;
    }

    .item-price {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        grid-row: span 2;
        margin-right: 20px;
    }

    .item-text {
        font-size: 1rem;
        padding-bottom: 1.2rem;
    }
</style>
