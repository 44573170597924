<script lang="ts">
    import type { Bundle,BundleItem, CustomerOrderData, Item } from "../types/types" ;
    import OrderItem from "./OrderItem.svelte";
    export let bundle: Bundle;
</script>

{#if bundle.bundleItems.length === 1 && bundle.orderQuantity === 1}
    {#each bundle.bundleItems as item}
        <OrderItem bind:item/>
    {/each}

{:else}
<div class="item {bundle.status}">
    <div class="bundle-name">{bundle.name}</div>
    <div class="bundle-wraper">
        <div class="bundle-count {bundle.orderQuantity > 1 ? bundle.status : ''}">
            {bundle.scannedQuantity}/{bundle.orderQuantity}
        </div>
        <div>
			{#each bundle.bundleItems as item (item.name)}
                <OrderItem bind:item/>
            {/each}
        </div>
    </div>

</div>
{/if}

<style>
    .bundle-count.not-scanned {
        color: red;
    }
    .bundle-count.completed {
        color: black;
    }
    .bundle-wraper {
        display: grid;
        grid-template-columns: auto auto;
    }
    .bundle-count {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        font-weight: 700;
    }
    .bundle-quantity {
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        grid-row: span 2;
    }
    .quantity-buttons {
        display: flex;
        justify-content: center;

    }
    .bundle-name{
        font-size: xxx-large;
        text-align: center;
    }
    .bundle-item.not-scanned {
        color: red;
    }

    .not-scanned {
        background-color: #f4f5d4;
    }
    .completed {
        background-color: yellowgreen;
    }
    .item {
        display: grid;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
        padding: 0.5rem;
        margin: 1rem;

    }

    .item-image {
        grid-row: span 2;
        text-align: center;
    }

    .item-name {
        font-size: 2rem;
        font-weight: 700;
    }

    .item-edition {
        font-size: 1.5rem;
        color: darkgray;
        padding-bottom: 1rem;
    }

    .item-quantity {
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        grid-row: span 2;
    }

    .item-price {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        grid-row: span 2;
    }

    .item-text {
        font-size: 1rem;
        padding-bottom: 1.2rem;
    }
</style>

