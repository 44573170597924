<script>
  const stylesheets = [...document.getElementsByTagName("link")];
  export let webSocketUrl;
  export let printServiceUrl;
  export let defaultShippingLabel;

  for (let i in stylesheets) {
    const sheet = stylesheets[i];
    const rel = sheet.getAttribute("rel");

    if (!!rel && rel.toLowerCase() === "stylesheet")
      sheet.parentNode.removeChild(sheet);
  }
  // Hack na odstranění CSS styles Espa
  import Router from "svelte-spa-router";

  import Home from "./pages/Home.svelte";
  import QueueSelection from "./pages/QueueSelection.svelte";
  import Expedition from "./pages/Expedition.svelte";
  import { webSocketUrl as storeWebSocketUrl,printServiceUrl as storePrintServiceUrl, defaultShippingLabel as storeDefaultShippingLabel} from './stores';

  const username = "expedice";
  const password = "expedice";
  const url = "/api/v1/App/user";

  /*  async function getToken() {
    console.log("test");
    const base64Credentials = btoa(`${username}:${password}`);
    const response = await fetch(url, {
      headers: {
        Authorization: `Basic ${base64Credentials}`,
        "Espo-Authorization": `${base64Credentials}`,
        "Espo-Authorization-By-Token": "false",
        "Espo-Authorization-Create-Token-Secret": "true",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        document.cookie = `auth-token=${data.token}; path=/`;
      });
  }*/

  storeWebSocketUrl.set(webSocketUrl);
  storePrintServiceUrl.set(printServiceUrl);
  storeDefaultShippingLabel.set(defaultShippingLabel);
  const routes = {
    "/": Home,
    "/queue": QueueSelection,
    "/expedition": Expedition,
  };
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css"
  />
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
  />
  <link
    rel="stylesheet"
    href="/client/modules/expedition-portal/lib/expedition-portal/style.css"
  />
</svelte:head>
<!-- {#await getToken() then} -->
<Router {routes} />

<!-- {/await} -->

<style>
  /* set variable bs-gutter-x from 1.5rem to 0  to fix the problem with overflowing of rows*/
  :global(.row) {
    --bs-gutter-x: 0;
  }
  :global(.col) {
    --bs-gutter-x: 1.5rem;
  }
</style>
