// src/lib/WebSocketPrinter.ts

export interface WebSocketPrinterOptions {
	url?: string;
	onConnect?: () => void;
	onDisconnect?: () => void;
	onUpdate?: (message: string) => void;
}

export class WebSocketPrinter {
	private websocket: WebSocket | null = null;
	private connected: boolean = false;
	private options: WebSocketPrinterOptions;

	constructor(options: WebSocketPrinterOptions = {}) {
		const defaults: WebSocketPrinterOptions = {
			url: 'ws://127.0.0.1:12212/printer',
			onConnect: () => {},
			onDisconnect: () => {},
			onUpdate: () => {},
		};

		this.options = { ...defaults, ...options };
		this.connect();
	}

	private connect() {
		this.websocket = new WebSocket(this.options.url!);

		this.websocket.onopen = () => {
			this.connected = true;
			this.options.onConnect?.();
		};

		this.websocket.onclose = () => {
			this.connected = false;
			this.options.onDisconnect?.();
			this.reconnect();
		};

		this.websocket.onmessage = evt => {
			this.options.onUpdate?.(evt.data);
		};

		this.websocket.onerror = err => {
			console.error('WebSocket error:', err);
			this.websocket?.close();
		};
	}

	private reconnect() {
		// Simple reconnection strategy: try to reconnect after 5 seconds
		setTimeout(() => {
			console.log('Attempting to reconnect WebSocket...');
			this.connect();
		}, 5000);
	}

	public submit(data: any) {
		if (this.connected && this.websocket?.readyState === WebSocket.OPEN) {
			const message = Array.isArray(data)
				? data.map(element => JSON.stringify(element))
				: JSON.stringify(data);
			if (Array.isArray(data)) {
				(message as string[]).forEach(msg => this.websocket?.send(msg));
			} else {
				this.websocket?.send(message as string);
			}
		} else {
			console.warn('WebSocket is not connected. Cannot send data.');
		}
	}

	public isConnected(): boolean {
		return this.connected;
	}

	public disconnect() {
		this.websocket?.close();
	}
}
